/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
	background-image: url('../../images/map.svg');
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 200px;
	text-align: center;
}
.contact-info h3 {
	font-size: 23px;
}
.contact-info a {
	color: #25d2db;
}
