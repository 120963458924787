@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
	margin: 0;
	padding: 0;
}

html {
	margin: 0 !important;
	overflow: auto !important;
}

body {
	color: $font-color;
	background-color: $color-light;
	font-family: $font-family;
	font-size: $font-size-normal;
	line-height: 1.7;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;
}

.relative {
	position: relative;
}
