// FONTS
$font-family-cursive: 'Abril Fatface', cursive;
$font-family: 'Noto Sans JP', sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;

$font-color: #4f5158;

$font-bold: 700;

// COLORS
$color-light: #f9f9f9;
$color-dark: #08002d;
$color-white: #fff;
$color-active: #25d2db;
$color-primary: #000000;
